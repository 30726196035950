import React from 'react';

import LogoImg from '../images/logo-appicon-192.png';
import Footer from '../elements/footer';

export function Legal() {
  return (
    <div className="page">
      <div className="relative bg-ui-1 overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-ui-1 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-text-3 transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav
                className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                aria-label="Global"
              >
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="/">
                      <span className="sr-only">mitgliederportal.org</span>
                      <img className="h-8 w-auto sm:h-10" src={LogoImg} />
                    </a>
                    <div className="hidden -mr-2 flex items-center md:hidden">
                      <button
                        type="button"
                        className="bg-ui-1 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Open main menu</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                  {/*<a href="#" className="font-medium text-gray-500 hover:text-gray-900">Product</a>*/}
                  {/*<a href="#" className="font-medium text-gray-500 hover:text-gray-900">Features</a>*/}
                  {/*<a href="#" className="font-medium text-gray-500 hover:text-gray-900">Marketplace</a>*/}
                  {/*<a href="#" className="font-medium text-gray-500 hover:text-gray-900">Company</a>*/}
                  <a
                    href="https://app.mitgliederportal.org"
                    className="font-medium text-primary-500 hover:text-primary-500"
                  >
                    Anmelden
                  </a>
                </div>
              </nav>
            </div>

            <div className="hidden absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md bg-ui-1 ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={LogoImg}
                      alt="Logo (Compass)"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="bg-ui-1 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                    >
                      <span className="sr-only">Close main menu</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/*                <div className="px-2 pt-2 pb-3 space-y-1">
                  <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Product</a>
                  <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Features</a>
                  <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Marketplace</a>
                 <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Company</a>
                </div>*/}
                <a
                  href="https://app.mitgliederportal.org"
                  className="block w-full px-5 py-3 text-center font-medium text-primary-500 bg-gray-50 hover:bg-gray-100"
                >
                  Anmelden
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 bg-ui-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <h2 className="text-base text-primary-500 font-semibold tracking-wide uppercase">
              Legal Notice
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Impressum
            </p>
          </div>
          <div className="">
            <h3 className="mt-6 text-xl  text-primary-500 font-semibold tracking-wide uppercase">
              Contact details
            </h3>
            <p className="mt-4 max-w-2xl">
              Julius Stoerrle<br/>
              Neuer Achterkamp 28<br/>
              22927 Großhansdorf, Germany<br/>
              <br/>
              mitgliederportal.org<br/>
              hello@mitgliederportal.org<br/>
            </p>
            <h3 className="mt-6 text-xl  text-primary-500 font-semibold tracking-wide uppercase">
              Financials
            </h3>
            <p className="mt-4 max-w-2xl">
              VAT: DE327078800
            </p>
            <h3 className="mt-6 text-xl text-primary-500 font-semibold tracking-wide uppercase">
              Online dispute resolution
            </h3>
            <p className="mt-4 max-w-2xl">
              Online dispute resolution in accordance with Article 14, Paragraph 1 of the ODR-VO (Online Dispute Resolution Regulations): The European Commission has established a platform for online dispute resolution (ODR). You can visit the platform at https://ec.europa.eu/consumers/odr.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Legal;
